#root .rv-discrete-color-legend.horizontal {
  white-space: normal;
}

#root
  .rv-discrete-color-legend-item.horizontal
  .rv-discrete-color-legend-item__title {
  margin-left: 6px;
  display: inline-block;
}

#root .rv-discrete-color-legend-item__color {
  height: 8px;
  width: 34px;
}

#root .rv-discrete-color-legend-item {
  padding: 0 8px;
}

#root .rv-discrete-color-legend {
  font-family: sans-serif;
}

#root .rv-discrete-color-legend-item.clickable:hover {
  background: #eee;
}

.plot-container {
  position: relative;
  /* z-index: 3; */
  flex-grow: 1;
}

.line-plot-container {
  cursor: crosshair;
}
.line-plot-container.unicorn {
  cursor: url('https://wandb.ai/unicorn.png'), crosshair;
}

.line-plot-container.zoomed-in {
  cursor: zoom-out;
}

.line-plot-container.zoomed-in:active {
  cursor: crosshair;
}
.line-plot-container.unicorn.zoomed-in:active {
  cursor: url('https://wandb.ai/unicorn.png'), crosshair;
}

.line-plot-container.max-zoom:active {
  cursor: not-allowed;
}

h4 {
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.legend-link, .legend-link:hover {
  color: inherit;
}

.legend-link:hover {
  filter: brightness(60%);
}