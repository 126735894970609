
.Toastify .toast-container {
  margin-top: 50px;
  max-width: 300px;
  min-width: 180px;
}
.Toastify .toast-container a {
  color: white;
  text-decoration: underline;
}

.Toastify .toast {
  background-color: var(--darkgray);
  color: var(--verylightgray);
  font-family: "Source Sans Pro";
  min-height: 50px;
  padding-left: 16px;
  padding-right: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 3px;
  line-height: 20px;
}

.Toastify .toast.Toastify__toast--error {
  background-color: rgb(189, 52, 25);
  color: white;
}

.Toastify .toast .close-toast {
  float: right;
  padding-top: 2px;
}