@font-face {
  font-family: 'graphein';
  src: url(./assets/fonts/grapheinpro-book-webfont.woff) format('woff');
}

@font-face {
  font-family: 'graphein';
  src: url(./assets/fonts/grapheinpro-bold-webfont.woff) format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'graphein';
  src: url(./assets/fonts/grapheinpro-bolditalic-webfont.woff) format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'graphein';
  src: url(./assets/fonts/grapheinpro-italic-webfont.woff) format('woff');
  font-style: italic;
}

@font-face {
  font-family: 'graphein';
  src: url(./assets/fonts/grapheinpro-light-webfont.woff) format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'graphein';
  src: url(./assets/fonts/grapheinpro-lightitalic-webfont.woff) format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "graphein";
  src: url(./assets/fonts/grapheinpro-black-webfont.woff) format('woff');
  font-weight: 900;
}

@font-face {
  font-family: 'panel-icons';
  src: url('./fonts/panel-icons.eot?61676432');
  src: url('./fonts/panel-icons.eot?61676432#iefix') format('embedded-opentype'),
    url('./fonts/panel-icons.woff?61676432') format('woff'),
    url('./fonts/panel-icons.ttf?61676432') format('truetype'),
    url('./fonts/panel-icons.svg?61676432#panel-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'graphein';
}
:root {
  --primaryColor: #2e78c7;
  --secondaryColor: #757575;
  --yellow: #ffc933;
  --red: #de6680;
  --green: #00B5A0;
  --blue: ##2e78c7;
  --darkgray: #515457;
  --gray: #757575;
  --lightgray: #aaaaaa;
  --verylightgray: #d2d2d2;
}
