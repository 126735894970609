.profile .negative.button {
  position: absolute;
  top: 15px;
  right: 10px;
  opacity: 0.8;
}

.profile .ui.fluid.card {
  box-shadow: 2px 2px 2px lightgray;
  border: 1px solid lightgray;
  border-radius: 0;
}

.profile .ui.card.content.cardContentBlock {
  padding: 11px;
}

.privacy-wrapper {
  display: flex;
  padding: 8px 0;
}

.privacy-wrapper:last-child {
  padding-bottom: 0;
}

.privacy-wrapper .project-access {
  margin-left: auto;
}

.ui.dropdown.account-type {
  margin-left: 6px;
}

.ui.dropdown.account-type i.dropdown.icon {
  margin-left: 6px;
}
